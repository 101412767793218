import { useContext } from "react";
import styled from "styled-components";
import { EventContext } from "context";
import ReactHtmlParser from "react-html-parser";

export const HeroVideo = ({ setModalVisible }) => {
  const { event } = useContext(EventContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const {
    background,
    // btnLink,
    // btnText,
    // heading,
    label,
    video,
  } = event.callToActions.heroVideo;

  const backgroundDesktop = `${api}/o/${storageDirectory}%2F${background.desktop}?alt=media`;
  const backgroundMobile = `${api}/o/${storageDirectory}%2F${background.mobile}?alt=media`;

  return (
    <HeroVideoContainer onClick={() => setModalVisible(true, video)}>
      <MobileImage src={backgroundMobile} alt={label} />
      <DesktopImage src={backgroundDesktop} alt={label} />
      <VideoLabel>{ReactHtmlParser(label)} &rsaquo;</VideoLabel>
    </HeroVideoContainer>
  );
};

const HeroVideoContainer = styled.div`
  position: relative;
  background-color: #e2e2e2;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 25px;
  overflow: hidden;
  cursor: pointer;

  & > img {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin: 0;
  }
`;

const MobileImage = styled.img`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const DesktopImage = styled.img`
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: block;
  }
`;

const VideoLabel = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 9, 91, 0.9);
  color: #ffffff;
  padding: 10px 20px;
  width: 100%;

  @media (max-width: 370px) {
    font-size: 0.95rem;
  }
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 12px;
  }
  span {
    font-size: 8px;
    position: relative;
    top: -5px;
  }
`;
