import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { fonts } from "theme/fonts";
import { firebase, EventContext, UserContext, AnalyticsContext } from "context";
import { useDocumentData } from "react-firebase-hooks/firestore";

export const PoweredBy = ({ zipCode, postCampaign }) => {
  const [dealer, setDealer] = useState();
  const [fdaf, loading, error] = useDocumentData(
    firebase.firestore().doc(`geo/ford/fdaf/${zipCode}`)
  );

  let string = fdaf?.fdaf;
  useEffect(() => {
    if (string) {
      let newStr = string
        .replace("Dealers", "")
        .replace("Greater Texas Ford", "Your Best In Texas Ford Dealers")
        .replace("North Texas Ford", "Your Best In Texas Ford Dealers")
        .replace("Houston Ford", "Your Local Ford Dealers")
        .replace("Oklahoma Ford", "Your Local Ford Dealers")
        .replace("St Louis Ford", "Your Local Ford Dealers")
        .replace("Midwest Ford", "Your Local Ford Dealers")
        .replace("Southern Quality Ford", "Your Local Ford Dealer")
        .replace("Midsouth Ford", "Your Local Ford Dealer")
        .replace("Cincinnatti North Ford", "Your Local Ford Dealers")
        .replace("Cincinnati North Ford", "Your Local Ford Dealers")
        .replace("Northern California Ford", "Your Local Ford Dealers");
      setDealer(newStr);
    }
  }, [string]);

  if (loading) return null;

  return (
    <SubBody>Powered by {dealer ? dealer : "Your Local Ford Dealers"}</SubBody>
  );
};

const SubBody = styled.p`
  font-size: 13px;
  z-index: 5;
`;
