import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from "react";

const Context = {
  location: null,
};

export const GeoContext = createContext(Context);

export const GeoProvider = ({ children }) => {
  const [location, setLocation] = useState(null);
  console.log("location", location);
  useEffect(() => {
    fetch("https://cdn.set.live", { method: "HEAD" })
      .then((res) => {
        const match = res.headers
          .get("x-client-geo")
          ?.match(/^US(?<location>[A-Z]{2})/);
        setLocation(match?.groups?.["location"] || "");
      })
      .catch((err) => {
        console.log(err);
        setLocation("");
      });
  }, []);

  if (location !== null) {
    Context.location = location;
  }
  return (
    <GeoContext.Provider value={Context}>
      {location !== null && children}
    </GeoContext.Provider>
  );
};

export const useGeo = () => useContext(GeoContext);
