import { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import backgroundDesktop from "assets/images/brand-pattern.jpg";
import backgroundMobile from "assets/images/brand-pattern-mobile.jpg";

export const PreCampaign = () => {
  const { event } = useContext(EventContext);
  const { heading } = event.preCampaign;

  return (
    <Layout preCampaign showHeader flexGrow showFooter>
      <PageContent
        backgroundDesktop={backgroundDesktop}
        backgroundMobile={backgroundMobile}
      >
        <h2>{ReactHtmlParser(heading)}</h2>
      </PageContent>
    </Layout>
  );
};

const PageContent = styled(MaxWidthContainer)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #00095b;
  color: #ffffff;
  background-position: top right;
  background-size: contain;
  background-image: url(${(props) => props.backgroundDesktop});
  background-repeat: no-repeat;

  @media ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.backgroundMobile});
    background-position: top right;
    background-size: contain;
  }

  h2 {
    font-size: clamp(18px, 9vw, 64px);
    margin: 50px 0;
  }
`;
