import { useState, useReducer, useContext, useEffect } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { reducer } from "Modules";
import { EventContext, UserContext, AnalyticsContext } from "context";
import { ButtonPrimary } from "Components/Buttons";
import { handleErrors, Field } from "Components/Form";
import { Heading, SubHeading } from "Routes/Root/Register";

export const RegistrationForm = ({
  formPart,
  formHeading,
  formSubHeading,
  formLegal,
  submitButton,
  handleZipFdafChange,
  setLoading,
}) => {
  const { event } = useContext(EventContext);
  const { writeToUserSubmissionDoc } = useContext(UserContext);
  const { logLeadSubmission, logClick } = useContext(AnalyticsContext);

  const [registration, setRegistration] = useReducer(reducer, {});
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const fields = event.register.fields;

  const eligibleZipCodes = JSON.parse(event.register.zipCodes);
  const zips = eligibleZipCodes.map((item) => item.zip);

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  useEffect(() => {
    document.getElementById("fordPolicy")?.addEventListener("click", () => {
      linkClickHandler(
        "https://www.max.live/politica-de-privacidad-y-terminos-de-servicio/",
        "Ford Privacy Policy",
        "external"
      );
    });

    document.getElementById("maxPolicy")?.addEventListener("click", () => {
      linkClickHandler(
        "https://www.max.live/politica-de-privacidad-y-terminos-de-servicio/",
        "MAX Privacy Policy",
        "external"
      );
    });
    return () => document.removeEventListener("click", () => {});
  }, []);

  const handleChange = (value, name) => {
    if (name === "ZIP") {
      handleZipFdafChange(value.ZIP);
    }
    setRegistration(value);
    delete errors[name];
    setErrors(errors);
  };

  useEffect(() => {
    setSubmitting(false);
  }, [submitButton]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorObject = handleErrors(fields, registration, formPart);

    if (!errorObject) {
      setSubmitting(true);
      setLoading(true);

      registration.formPart = formPart;
      await writeToUserSubmissionDoc(registration);
      logLeadSubmission({ formPart, registration });
      setTimeout(() => setLoading(false), 2000);
    } else {
      setSubmitting(false);
      setErrors(errorObject);
    }
  };

  return (
    <RegForm onSubmit={handleSubmit}>
      {fields && (
        <Form>
          {fields
            .sort((a, b) => a.order - b.order)
            .filter((field) => {
              return field.formPart === formPart;
            })
            .map((field, index) => {
              let mobileSize = "100%";
              let desktopSize = "100%";
              let selectSize = "99%";
              if (field.span === "half") desktopSize = "calc(49% - 1px)";

              if (
                field.span !== "full" &&
                (field.spanMobile === "half" || !field.spanMobile)
              )
                mobileSize = "calc(46% - 4px)";

              return (
                <FieldContainer
                  sizeDesktop={
                    field.type === "select" ? selectSize : desktopSize
                  }
                  sizeMobile={mobileSize}
                  fullSize={field.span === "full"}
                  key={index}
                  order={field.order}
                >
                  {field.type !== "optIn" && !!field.label && (
                    <Label>{field.label}</Label>
                  )}
                  <Field
                    field={field}
                    value={registration[field.name]}
                    onChange={(value) => handleChange(value, field.name)}
                    error={{
                      status: errors[field.name],
                      message: errors[field.name]?.message,
                    }}
                  />
                </FieldContainer>
              );
            })}

          {formLegal && (
            <FormLegal
              onClick={() =>
                linkClickHandler(
                  "Sweepstakes Rules link",
                  "Sweepstakes Rules",
                  "internal"
                )
              }
            >
              {ReactHtmlParser(formLegal)}
            </FormLegal>
          )}
        </Form>
      )}

      {submitting ? (
        <RegisterButton>Loading</RegisterButton>
      ) : (
        <RegisterButton
          type="submit"
          onClick={() =>
            linkClickHandler("Registration button", "Registration", "internal")
          }
        >
          {submitButton ? submitButton : "Submit"}
        </RegisterButton>
      )}
    </RegForm>
  );
};

const FormTitle = styled.h2`
  text-transform: uppercase;
  margin: 12px 0;
`;

const Form = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 20px;
  margin: 22px 0 20px;
  width: 100%;

  @media (min-width: 951px) {
    gap: 10px;
    row-gap: 25px;
  }

  @media (max-width: 500px) {
    gap: 25px;
    row-gap: 25px;
  }
`;

const RegForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
  button {
    margin: auto;
  }
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 ${(props) => props.sizeDesktop};
  ${(props) => props.order && `order: ${props.order};`}
  width: 100%;
  @media (max-width: 950px) {
    flex: 0 1 ${(props) => props.sizeMobile};
  }
`;

const Label = styled.span`
  color: #00095b;
  font-size: 11px;
  margin-bottom: 5px;
`;

const FormLegal = styled.div`
  font-size: 11px;
  line-height: 1.5;
  margin: 7px 0;
  order: 99;
  color: #00095b;

  a,
  a:visited {
    color: #00095b;
  }
`;

const RegisterButton = styled(ButtonPrimary)`
  margin-bottom: 50px;
  width: 35%;
  @media (max-width: 950px) {
    margin: 25px 0 25px 0;
    width: 50%;
    padding: 10px 0;
    font-size: 0.85rem;
    align-self: center;
  }
`;
