const brand = {
  light: "F1Light",
  regular: "F1Regular",
  regularItalic: "F1RegularItalic",
  medium: "F1Medium",
  bold: "F1Bold",
  condensedBold: "F1CondensedBold",
};

const base = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`;
const emoji = "MaxEmoji";

const fallbacks = {
  default: `Roboto, ${base}, ${emoji}`,
  header: `Montserrat, ${base}, ${emoji}`,
};

export const fonts = {
  brand,
  default: `${brand.regular}, ${fallbacks.default}`,
  header: `${brand.medium}, ${fallbacks.header}`,
};
