import styled from "styled-components";
import { FieldMessage } from "Components";

export const Input = ({
  onChange,
  value = "",
  placeholder,
  error,
  success,
  disabled,
  placeholderStyle,
  ...props
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <TextContainer error={error} disabled={disabled}>
      <Field
        onChange={handleChange}
        value={value}
        error={error}
        disabled={disabled}
        success={success}
        {...props}
      />
      <Placeholder error={error} visible={value} htmlFor={props.name}>
        {placeholder}
        {success && (
          <FieldMessage
            content={error.message}
            type={error.status ? "error" : "success"}
            style={{
              marginTop: "0",
              marginLeft: "8px",
            }}
          />
        )}
      </Placeholder>
    </TextContainer>
  );
};

const primaryColor = "#00095B";
const inputPadding = "10px 12px";
const borderRadius = "3px";
const successBorderColor = "#008200";
const errorBackground = "#fff";
const errorBorderColor = "#E45C52";
const errorFieldColor = "#00095b";

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => (props.disabled ? "#A6AEB2" : "#ffffff")};
  ${(props) => props.error && `background-color: ${errorBackground};`}
  border-radius: ${borderRadius};
  position: relative;
`;

const Placeholder = styled.label`
  display: flex;
  flex-direction: row;
  color: ${(props) => (props.error ? `${errorFieldColor}` : `${primaryColor}`)};
  position: absolute;
  top: 12px;
  left: 12px;
  transition: all 0.2s ease-in-out;
  font-size: 0.7rem;
  font-family: "F1Regular";

  ${(props) =>
    props.visible &&
    `
    top: -20px;
    left: 0;
    font-size: 12px;
  `}
`;

const Field = styled.input`
  background-color: transparent;
  border: 1px solid ${primaryColor};
  border-radius: ${borderRadius};
  outline: 0;
  padding: ${inputPadding};
  position: relative;
  width: 100%;
  -webkit-appearance: none;
  color: ${primaryColor};
  z-index: 1;

  &:focus {
    border: 1px solid #4580dd;
  }

  &:focus + label {
    top: -20px;
    left: 0;
    padding: 0;
    font-size: 12px;
    color: ${primaryColor};
  }

  &:disabled {
    border: 1px solid #626466;
  }

  ${(props) =>
    props.error &&
    `
    border: 2px solid ${errorBorderColor};

    &:focus {
      border: 2px solid ${errorBorderColor};
    }
  `};
`;
