import { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext, UserContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";
import { PoweredBy } from "../Components/PoweredBy";
import secondaryLogo from "assets/images/ford-music-logo.png";

export const Footer = ({
  showFooter,
  className,
  preCampaign,
  registered,
  postCampaign,
}) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);
  const { userType, getUserRegistration } = useContext(UserContext);

  useEffect(() => {
    getUserRegistration().then((data) => {
      setUserData(data);
    });
  }, [userType]);

  const [userData, setUserData] = useState();
  const [zip, setZip] = useState();
  useEffect(() => {
    if (userData) {
      setZip(userData.ZIP);
    }
  }, [userData]);

  const { secondary } = event.logos;

  const { maxPolicy, brandDisclaimer } = event.footer;

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  return (
    <>
      {showFooter && (
        <FooterWrapper>
          <FooterContainer preCampaign={preCampaign}>
            <div>
              {!preCampaign && brandDisclaimer && (
                <BrandDisclaimer
                  onClick={() =>
                    linkClickHandler(
                      "Sweepstakes Rules",
                      "Sweepstakes Rules",
                      "footer"
                    )
                  }
                >
                  {ReactHtmlParser(brandDisclaimer)}
                </BrandDisclaimer>
              )}

              <MaxPolicy
                onClick={() =>
                  linkClickHandler(
                    "https://www.max.live/",
                    "MAX homepage/privacy policy",
                    "footer"
                  )
                }
              >
                {ReactHtmlParser(maxPolicy)}{" "}
              </MaxPolicy>
              <CookieButton
                onClick={() => {
                  if (window.Cookiebot) {
                    window.Cookiebot.renew();
                  }
                }}
              >
                Click Here to set Cookie Preferences.
              </CookieButton>
            </div>
          </FooterContainer>
          <PoweredByContainer>
            <div>
              {(registered || postCampaign) && <PoweredBy zipCode={zip} />}
            </div>
            <Link
              href={secondary?.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                linkClickHandler(
                  secondaryLogo.link,
                  "Musica Ford Hub",
                  "footer"
                )
              }
            >
              <Logo src={secondaryLogo} />
            </Link>
          </PoweredByContainer>
        </FooterWrapper>
      )}
    </>
  );
};

const FooterWrapper = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  position: relative;
  font-weight: 600;
  padding: 30px 50px 30px;
  width: 100%;
  max-width: 1200px;
  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 15px;
  }
`;

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  font-size: 10px;
  z-index: 1;

  ${(p) =>
    !p.preCampaign &&
    css`
      margin-top: 80px;
      @media ${({ theme }) => theme.maxMedia.medium} {
        margin-top: 40px;
      }
    `};
`;

const PoweredByContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  font-weight: 600;
  @media ${(props) => props.theme.maxMedia.medium} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Link = styled.a`
  margin: 15px;
  align-self: center;
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 45px;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 252px;
`;

const MaxPolicy = styled.p`
  margin-top: 0;
  font-size: 10px;
  font-family: "F1Regular";
  font-weight: 500;
`;

const BrandDisclaimer = styled.div`
  margin-bottom: 20px;
  font-size: 10px;
  font-family: "F1Regular";
  font-weight: 500;
`;

export const CookieButton = styled.button`
  border: none;
  background-color: transparent;
  display: inline;
  color: #00095b;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font-size: 10px;
`;
