import { useContext } from "react";
import styled from "styled-components";
import { EventContext, AnalyticsContext, GeoContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import brandLogoImgGA from "assets/logos/ga-truck-month-logo-blue.png";
import brandLogoImgTX from "assets/logos/tx-truck-month-logo-blue.png";
library.add(fas, fab);

export const Header = ({ showHeader, registration, preCampaign }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);
  const { location } = useContext(GeoContext);

  const { brand: brandLogo } = event.logos;

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  return (
    <>
      {showHeader && (
        <HeaderWrapper as="header" registration={registration}>
          <HeaderContainer preCampaign={preCampaign}>
            <Link
              href={brandLogo.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                linkClickHandler(brandLogo.link, "Ford Homepage", "header")
              }
            >
              <Logo src={location === "TX" ? brandLogoImgTX : brandLogoImgGA} />
            </Link>
          </HeaderContainer>
        </HeaderWrapper>
      )}
    </>
  );
};

const HeaderWrapper = styled.header`
  z-index: 1;
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 0 25px;
  }
`;

const HeaderContainer = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 25px 0 24px;
`;

const Link = styled.a`
  margin: auto;
`;

const Logo = styled.img`
  width: 100%;
  object-fit: cover;
  max-width: 238px;
  @media ${(props) => props.theme.maxMedia.medium} {
    max-width: 151px;
  }
`;
