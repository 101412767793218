import styled from "styled-components";
import { Select as Select2 } from "Components/SelectSource";
import { FieldMessage } from "Components";

export const Select = ({ onChange, value, field, error = {} }) => {
  let selected = value && field.options.find((option) => option.id === value);

  const handleChange = (value) => {
    onChange({ [field.name]: value });
  };

  return (
    <>
      {!error.status && value && (
        <FieldMessage
          content={error.message}
          type={error.status ? "error" : "success"}
          style={{
            marginTop: "0",
            marginBottom: "8px",
          }}
          field={field}
        />
      )}
      <StyledSelect
        placeholder={field.placeholder ?? "Select an option..."}
        selected={selected}
        options={field.options}
        error={!!error.status}
        value={!!value}
        onChange={(val) => handleChange(val.id)}
      />
      {error.status && (
        <FieldMessage
          active={value || !!error.status}
          content={error.message}
          type={error.status ? "error" : "success"}
        />
      )}
    </>
  );
};

const primaryColor = "#00095B";
const borderRadius = "3px";
const errorBackground = "#EDB8B4";
const errorBorderColor = "#E45C52";
const errorFieldColor = "#6d2621";

const StyledSelect = styled(Select2)`
  border-radius: ${borderRadius};
  border-color: ${primaryColor};
  padding-top: 9px;
  padding-bottom: 15px;
  height: 41px;

  span {
    font-size: 12px;
    color: ${primaryColor};
  }

  & > svg > path {
    fill: ${primaryColor};
  }

  ${(props) =>
    props.error &&
    `
    border: 2px solid ${errorBorderColor};



    &:focus {
      border-width: 1px;
    }
  `};

  ${(props) =>
    props.value &&
    `
    span {
      font-size: 16px;
      font-weight: 600;
    }`}
`;
