import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import {
  EventContext,
  AnalyticsContext,
  UserContext,
  GeoContext,
} from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import { Modal, Media16By9, MediaVideo, ButtonPrimary } from "Components";
import { RingSpinner } from "Components/RingSpinner";
import { RegistrationForm } from "Components/Form";
import { PoweredBy } from "Components/PoweredBy";
import { ButtonOutlined } from "Components/Buttons/ButtonOutlined";
import { useMediaQuery } from "hooks/useMediaQuery";
import brandPatternImg from "assets/images/brand-halftone-pattern.png";
import fordSignImg from "assets/images/ford-script-animation.gif";
import brandLogoImgGA from "assets/logos/ga-truck-month-logo-white.png";
import brandLogoImgTX from "assets/logos/tx-truck-month-logo-white.png";

export const RegistrationLayout = React.memo(
  ({ formStep = 0, registered, postCampaign }) => {
    const { event } = useContext(EventContext);
    const { logClick } = useContext(AnalyticsContext);
    const { location } = useContext(GeoContext);

    const { userType, getUserRegistration } = useContext(UserContext);

    const [open, setOpen] = useState(false);
    const [video, setVideo] = useState(null);
    const [loading, setLoading] = useState(false);

    const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
    const storageDirectory = event.meta.storageDirectory;

    const formRef = useRef();
    const isMobile = useMediaQuery();

    // logo
    const { brand: brandLogo } = event.logos;

    // images
    const {
      heroThumb,
      heroArtist,
      artistBg,
      artistBgMob,
      artistCTA,
      brandCTA,
      mainBrandCta,
    } = event.images;
    const heroThumbImg = `${api}/o/${storageDirectory}%2F${heroThumb}?alt=media`;
    const artistImg = `${api}/o/${storageDirectory}%2F${heroArtist}?alt=media`;
    const artistBgImg = `${api}/o/${storageDirectory}%2F${artistBg}?alt=media`;
    const artistBgMobImg = `${api}/o/${storageDirectory}%2F${artistBgMob}?alt=media`;
    const artistCTAImg = `${api}/o/${storageDirectory}%2F${artistCTA}?alt=media`;
    const brandCTAImg = `${api}/o/${storageDirectory}%2F${brandCTA}?alt=media`;
    const mainBrandCtaImg = `${api}/o/${storageDirectory}%2F${mainBrandCta}?alt=media`;

    if (registered) formStep = event.register.sweeps.length - 1;

    // registration content
    const {
      heading,
      subHeading,
      body,
      formHeading,
      formSubHeading,
      formLegal,
      submit,
    } = event.register.sweeps[formStep];

    // music video background
    const {
      heading: musicVideoHeadline,
      video: musicVideoLink,
    } = event.callToActions.musicVideo;

    // brand cta
    const {
      heroVideoLink,
      btnLink: brandCTALink,
      btnText: brandCTAText,
      heading: brandCTAHeadline,
    } = event.callToActions.brand;
    // let brandCTAImg = `${api}/o/${storageDirectory}%2F${brandCTABg.desktop}?alt=media`;

    // brand product cta
    const {
      btnLink: brandProductLink,
      btnText: brandProductText,
      heading: brandProductHeading,
      body: brandProductBody,
    } = event.callToActions.brandSecondary;

    // post-campaign content
    const {
      heading: postCampaignHeading,
      subHeading: postCampaignSubHeading,
      body: postCampaignBody,
    } = event.postCampaign;

    const linkClickHandler = (url, label, category) => {
      logClick({ label, url }, category);
    };

    const setModalVisible = (bool, link) => {
      setVideo(link);
      setOpen(bool);
    };

    const scrollToSection = (ref) => {
      window.scrollTo({ top: ref.current.offsetTop });
      linkClickHandler("Register Below btn", "Register Below", "internal");
    };

    const [fdaf, setFdaf] = useState();
    const handleZipFdafChange = (zip) => {
      setFdaf(zip);
    };
    useEffect(() => {
      getUserRegistration().then((data) => {
        setUserData(data);
      });
    }, [userType]);

    const [userData, setUserData] = useState();
    const [zip, setZip] = useState();
    useEffect(() => {
      if (userData) {
        setZip(userData.ZIP);
      }
    }, [userData]);

    if (loading) {
      window.scrollTo(0, 0);
      return <RingSpinner />;
    }

    return (
      <Layout
        registration={postCampaign ? false : true}
        flexGrow
        showFooter
        registered={registered}
        postCampaign={postCampaign}
      >
        <Container>
          <HeroSection>
            <HeroContainer>
              <HeroImage
                src={heroThumbImg}
                alt="Artist image with dark blue overlay"
              />
              <FloatContent>
                {
                  <LogoFloat mobile={isMobile}>
                    <Link
                      href={brandLogo.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() =>
                        linkClickHandler(
                          brandLogo.link,
                          "Ford Homepage",
                          "header"
                        )
                      }
                    >
                      <Logo
                        src={
                          location === "TX" ? brandLogoImgTX : brandLogoImgGA
                        }
                      />
                    </Link>
                  </LogoFloat>
                }
                <HeadingContainer>
                  {(subHeading || (postCampaign && postCampaignSubHeading)) && (
                    <Body>
                      {postCampaign
                        ? ReactHtmlParser(postCampaignSubHeading)
                        : ReactHtmlParser(subHeading)}
                    </Body>
                  )}
                  {(heading || (postCampaign && postCampaignHeading)) && (
                    <MainHeading>
                      {postCampaign
                        ? ReactHtmlParser(postCampaignHeading)
                        : ReactHtmlParser(heading)}
                    </MainHeading>
                  )}
                  {(body || (postCampaign && postCampaignBody)) && (
                    <Body>
                      {postCampaign
                        ? ReactHtmlParser(postCampaignBody)
                        : ReactHtmlParser(body)}
                    </Body>
                  )}
                </HeadingContainer>
                {!registered && !postCampaign && (
                  <ButtonOutlined onClick={() => scrollToSection(formRef)}>
                    Register Below
                  </ButtonOutlined>
                )}
                {(registered || postCampaign) && (
                  <PoweredBy zipCode={zip ? zip : fdaf} />
                )}
              </FloatContent>
            </HeroContainer>

            <HeroContent>
              <PatternImg src={brandPatternImg} />
              <ArtistImgReg src={artistImg} />
              <BrandSignature>
                <FordSign src={fordSignImg} />
              </BrandSignature>
            </HeroContent>
          </HeroSection>

          <VideoSection>
            <CallOutContainer>
              <MainHeading>
                “Almost every memory
                <br />
                <span class="brand-color">I have is in a Ford.”</span>
              </MainHeading>
              <ButtonPrimary
                onClick={() => {
                  setModalVisible(true, heroVideoLink);
                  linkClickHandler(heroVideoLink, "Hero Video", "internal");
                }}
              >
                Watch Video
              </ButtonPrimary>
            </CallOutContainer>
          </VideoSection>

          <ArtistBackground src={isMobile ? artistBgMobImg : artistBgImg} />

          {!registered && !postCampaign && (
            <Content>
              <>
                {formHeading && (
                  <Heading ref={formRef}>
                    {ReactHtmlParser(formHeading)}
                  </Heading>
                )}
                {formSubHeading && (
                  <SubHeading>{ReactHtmlParser(formSubHeading)}</SubHeading>
                )}
                <RegistrationForm
                  formPart={formStep}
                  formHeading={formHeading}
                  formSubHeading={formSubHeading}
                  formLegal={formLegal}
                  submitButton={submit}
                  setLoading={setLoading}
                  handleZipFdafChange={handleZipFdafChange}
                />
              </>
            </Content>
          )}

          <MainBrandCTA>
            <CTAContainer>
              <CTAImageWrap>
                <CTAImage src={mainBrandCtaImg} />
              </CTAImageWrap>
              <CTAContent>
                {brandProductHeading && (
                  <Heading>{ReactHtmlParser(brandProductHeading)}</Heading>
                )}
                {brandProductBody && (
                  <Body>{ReactHtmlParser(brandProductBody)}</Body>
                )}
                <ButtonPrimary
                  onClick={() => {
                    window.open(brandProductLink, "_blank");
                    linkClickHandler(
                      brandProductLink,
                      "Explore Features",
                      "external"
                    );
                  }}
                >
                  {brandProductText}
                </ButtonPrimary>
              </CTAContent>
            </CTAContainer>
            <PatternImg src={brandPatternImg} />
          </MainBrandCTA>

          <SecondaryCTA>
            <BrandCTA>
              <ImgCTA src={brandCTAImg} />
              {brandCTAHeadline && (
                <SubHeading>{ReactHtmlParser(brandCTAHeadline)}</SubHeading>
              )}
              <ButtonPrimary
                onClick={() => {
                  window.open(brandCTALink, "_blank");
                  linkClickHandler(brandCTALink, "Build & Price", "external");
                }}
              >
                {brandCTAText}
              </ButtonPrimary>
            </BrandCTA>
            <ArtistCTA>
              <ImgCTA src={artistCTAImg} />
              {musicVideoHeadline && (
                <SubHeading>{ReactHtmlParser(musicVideoHeadline)}</SubHeading>
              )}
              <ButtonPrimary
                onClick={() => {
                  window.open(musicVideoLink, "_blank");
                  linkClickHandler(musicVideoLink, "Artist song", "external");
                }}
              >
                Listen
              </ButtonPrimary>
            </ArtistCTA>
          </SecondaryCTA>
        </Container>

        <Modal open={open} setOpen={setModalVisible}>
          <Media16By9>
            <MediaVideo
              src={video}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </Media16By9>
        </Modal>
      </Layout>
    );
  }
);

export const Heading = styled.h2`
  font-size: 40px;
  div {
    white-space: nowrap;
    display: inline;
  }
  span.brand-color {
    color: #066fef;
  }
  span#mark {
    font-size: 21px;
    position: relative;
    top: -15px;
  }
`;

export const SubHeading = styled.h3`
  font-size: 20px;
`;

export const Body = styled.p`
  font-size: 1rem;
  z-index: 5;
  span {
    font-size: 10px;
    position: relative;
    top: -4px;
  }
`;

export const MainHeading = styled.h1`
  font-size: 60px;
  position: relative;
  span.brand-color {
    color: #066fef;
  }
  span#mark {
    font-size: 25px;
    position: relative;
    top: -15px;
  }
  @media (max-width: 950px) {
    font-size: 55px;
  }
`;

const Container = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 0px;
  overflow: hidden;
`;

const Content = styled.div`
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 70px 0;
  h1,
  h2,
  h3 {
    text-align: center;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
    padding: 80px 24px 50px;
    h2 {
      font-size: 35px;
    }
  }
`;

const Link = styled.a`
  width: 100%;
`;

const Logo = styled.img`
  width: 100%;
  display: flex;
  max-width: 238px;
  @media ${(props) => props.theme.maxMedia.medium} {
    max-width: 151px;
  }
`;

const HeroImage = styled.img`
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-bottom-right-radius: 8px;

  @media ${(props) => props.theme.maxMedia.medium} {
    object-position: 50% 20%;
  }

  @media ${({ theme }) => theme.media.small} {
    object-fit: cover;
    object-position: 70% 50%;
  }
`;

const HeroSection = styled.div`
  width: 100%;
  display: flex;
  min-height: fit-content;
  align-items: flex-start;
  @media ${(props) => props.theme.maxMedia.medium} {
    flex-direction: column;
    ${({ reverse }) =>
      reverse &&
      css`
        flex-direction: column-reverse !important;
      `}
  }
`;

const HeroContainer = styled.div`
  width: 55%;
  display: flex;
  position: relative;
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
  }
`;

const LogoFloat = styled.div`
  width: 100%;
  display: flex;
  z-index: 1;
  a {
    width: max-content;
  }

  @media ${({ theme }) => theme.maxMedia.medium} {
    justify-content: center;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 120px;

  @media (max-width: 950px) {
    margin-top: 80px;
    max-width: 440px;
  }
  @media ${({ theme }) => theme.media.small} {
    ${MainHeading} {
      max-width: 310px;
    }
  }
`;

const FloatContent = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  padding: 120px 0 180px;
  margin: auto;
  color: #fff;
  ${MainHeading} {
    margin-top: 0;
    margin-bottom: 0;
  }
  button {
    margin-top: 44px;
    width: 220px;
    z-index: 5;
  }

  ${LogoFloat} + ${Body} {
    margin-bottom: 20px;
  }

  @media (max-width: 950px) {
    width: 80%;
    padding: 52px 0 67px;
    button {
      margin-top: 30px;
    }
  }
  @media ${({ theme }) => theme.media.small} {
  }
`;

const HeroContent = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  position: relative;
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
  }
`;

const PatternImg = styled.img`
  width: 65%;
  object-fit: cover;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
`;

const ArtistImgReg = styled.img`
  width: 75%;
  object-fit: cover;
  z-index: 1;
  margin: 11% auto 0;
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 80%;
    margin-bottom: 10px;
  }
`;

const VideoSection = styled.div`
  width: 100%;
  display: flex;
  @media ${(props) => props.theme.maxMedia.medium} {
    flex-direction: column-reverse;
  }
`;

const CallOutContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 6% 0 7% 9%;
  button {
    width: 180px;
    font-size: 16px;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
    align-items: flex-start;
    padding: 5% 40px 15%;
    img {
      max-width: 400px;
    }
    ${MainHeading} {
      max-width: 272px;
    }
  }
`;
const BrandSignature = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 52px auto -20px;

  @media ${(props) => props.theme.maxMedia.medium} {
    justify-content: flex-start;
    margin: 30px auto;
    padding: 0 24px;
  }
`;
const FordSign = styled.img`
  width: 243px;
  object-fit: contain;
`;
const ArtistBackground = styled.img`
  width: 100%;
  object-fit: contain;
`;

const MainBrandCTA = styled.div`
  width: 100%;
  display: flex;
  background-color: #00142e;
  padding: 24px;
  position: relative;
  ${PatternImg} {
    top: initial;
    left: initial;
    right: 0;
    bottom: 0;
    width: 45%;
    transform: rotate(180deg);
  }

  @media ${({ theme }) => theme.maxMedia.medium} {
    ${PatternImg} {
      width: 100%;
    }
  }
`;

const CTAContainer = styled.div`
  width: 80%;
  max-width: 1000px;
  margin: 76px auto;
  display: flex;
  gap: 10%;
  z-index: 10;
  @media ${(props) => props.theme.maxMedia.medium} {
    flex-direction: column-reverse;
    width: 100%;
    max-width: 500px;
    margin: 26px auto 96px;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }
`;

const CTAContent = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  color: #fff;
  justify-content: center;
  button {
    margin-top: 10%;
    font-size: 15px;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
    align-items: flex-start;
  }
`;

const CTAImageWrap = styled.div`
  width: 55%;
  display: flex;
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
  }
`;

const CTAImage = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin-right: -3px;

  @media ${({ theme }) => theme.maxMedia.medium} {
    margin: 0 8px 10px;
    width: calc(100% - 16px);
  }
`;

const ImgCTA = styled.img`
  width: 100%;
  object-fit: cover;
`;

const SecondaryCTA = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  gap: 20px;
  padding: 100px 70px 0;
  @media ${(props) => props.theme.maxMedia.medium} {
    flex-direction: column;
    gap: 40px;
    padding: 35px 24px;
  }
`;

const BrandCTA = styled.div`
  width: 58.5%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  img {
    aspect-ratio: 1.5;
  }
  button {
    width: 220px;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
    button {
      margin-top: -25px;
    }
  }
`;

const ArtistCTA = styled.div`
  gap: 25px;
  width: 41.5%;
  flex-direction: column;
  display: flex;
  img {
    aspect-ratio: 1.06;
  }
  button {
    width: 220px;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
    button {
      margin-top: -25px;
    }
  }
`;
