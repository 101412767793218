import { TimeProvider, EventProvider, GeoProvider } from "context";
import { Routes } from "./Routes";
import "./App.css";

function App() {
  return (
    <TimeProvider>
      <EventProvider>
        <GeoProvider>
          <Routes />
        </GeoProvider>
      </EventProvider>
    </TimeProvider>
  );
}

export default App;
