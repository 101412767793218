import styled from "styled-components";
import { fonts } from "theme/fonts";

export const ButtonOutlined = ({
  as,
  href,
  className,
  onClick,
  target,
  rel,
  children,
}) => {
  return (
    <Wrapper
      as={as}
      href={href}
      className={className}
      onClick={onClick}
      target={target}
      rel={rel}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  display: inline-block;
  padding: 10px 30px;
  border: 0px;
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border-radius: 25px;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(256, 256, 256, 0.2);
  }
`;
